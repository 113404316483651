.button-group-left {
    flex-shrink: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

table {
    border-collapse: collapse;
    width: 100%;
  }
  
  tr{
    display: flex;
  }
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    width: 100%;
}
.order-detail-container {
    overflow-x: hidden;
  }

  