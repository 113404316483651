.card-text:hover::before {
    content: attr(data-barcode);
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    border: 1px solid grey;
    background-color: white;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  }
  .custom-modal .modal-dialog {
    max-width: 80%;
    margin: auto;
    top: 10%;
    transform: translate(-50%, 50%);
  }
  .read-only {
    border: 1px solid red;
  }
  .form-control[readonly]  {
    border: 1px solid red;
  }
 

  