.grid-bg-colour {
    background-color: #313943;
}

.spacer{
    width:100px;
}

.filter-row {
    display: flex;
    width: auto;
    height: 40px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.button-group-left {
    flex-shrink: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.button-group-right {
    flex-shrink: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    margin-right: 15px;
}

.task-amount-heading {
    display: inline;
    position: absolute;
    top: 10px;
    right: 50%;
    margin: 5px;
    color: white;
  }

.grid-div {
    flex-direction: row;
    height: 80vh;
}

  