.calendarContainer {
    position: centered;
    margin-bottom: 60px;
  }

.rbc-toolbar-label {
  font-size: 24px;
  text-decoration: underline;
  font-weight: bold;
}

.rbc-header {
  font-size: 20px;
  text-decoration: underline;
  font-weight: bold;
}
.rbc-row {
  font-size: 24px;
 
}
.rbc-events-container{
  width: 80%;
}
