.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.login-logo {
   
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
}

.failure-modal .modal-content {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
  .failure-modal .btn-secondary {
    background-color: #f5c6cb;
    border-color: #f5c6cb;
    color: #721c24;
  }
  